import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
    return (
      <section id='about'>
        <h5> Get To Know</h5>
        <h2> About Me</h2>

        <div className="container about__container">
          <div className="about_me">
            <div className="about__me-image">
              <img class="img-responsive" src = {ME} alt="About Image"/>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
            <FaAward className="about__icon"/>
              <h5>Experience</h5>
              <small> 2 Year Professional Experience </small>
            </article>

            <article className="about__card">
            <FiUsers className="about__icon"/>
              <h5>Clients</h5>
              <small> 1000 + </small>
            </article>

            <article className="about__card">
            <VscFolderLibrary className="about__icon"/>
              <h5>Projects</h5>
              <small> 20+ Completed</small>
            </article>
          </div>


        </div>
        <p>
I am a software developer for BabyQuip Inc. and I live in the San Francisco bay area. 
I love to play guitar, make web devlopment courses and skateboard. 
          </p>

          <a href = "#contact" className = 'btn btn-primary'>Let's Talk</a>
        </div>

      </section>
    );
};
export default About